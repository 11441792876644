import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React, { useEffect } from 'react'
import useDownload from '../../hooks/useDownload'
import BreakPoints from '../../styles/breakPoints'
import { ManagementKindList } from '../../types/ManagementKind'
import StringUtil from '../../utils/StringUtil'
import CommonCheckbox from '../atoms/commonCheckbox'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import { SiteKind } from '../../types/SiteKind'

const Wrapper = styled.section`
  width: 100%;
  padding: 80px 0;
  .p-section-inner {
    h1 {
      img {
        display: block;
        width: 100%;
        margin-bottom: 40px;
      }
    }
    max-width: 700px;
    padding: 50px 80px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 30px;
    .p-section-contact {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      .item-wrap {
        margin: 0px 0px 20px;
        &.name-wrap {
          display: flex;
          div {
            width: 50%;
            margin: 0;
          }
        }
        .label-area {
          display: flex;
          flex-wrap: wrap;
          padding: 0 0 2px;
          .txt-color-alert {
            margin: 0 5px;
            &.require {
              opacity: 0.6;
            }
            &.err {
              margin-left: auto;
              word-break: keep-all;
            }
          }
        }
        textarea {
          min-height: 120px;
        }
      }
    }
    .p-top-data-wrap {
      background: #f9f3c4;
      width: 100%;
      border-radius: 8px;
      margin: -30px auto 60px;
      padding: 20px;
      text-align: center;
      p {
        margin: 0 0 20px;
      }
      button {
        margin: 0;
      }
    }
    .p-sub-data-wrap {
      border: solid 2px #dbdee1;
      background: #f7fbfa;
      width: 100%;
      border-radius: 8px;
      margin: 30px auto 0;
      padding: 20px;
    }
    .p-select {
      overflow: hidden;
      width: 90%;
      text-align: center;
      position: relative;
      border: solid 2px #ebebeb;
      background: #ffffff;
      width: 100%;
      min-height: 40px;
      border-radius: 8px;
      transition: 0.3s;
      &:hover {
        border-color: #3ec7b3;
      }
      &:before {
        position: absolute;
        top: 45%;
        right: 0.8em;
        width: 0;
        height: 0;
        padding: 0;
        content: '';
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #272d2c;
        pointer-events: none;
      }
      select {
        width: 100%;
        padding-right: 1em;
        cursor: pointer;
        text-indent: 0.01px;
        text-overflow: ellipsis;
        border: none;
        outline: none;
        background: transparent;
        background-image: none;
        box-shadow: none;
        -webkit-appearance: none;
        appearance: none;
        padding: 8px 38px 8px 8px;
        &:-ms-expand {
          display: none;
        }
      }
    }
    .p-btn-download {
      font-size: 24px;
      height: 60px px;
      padding: 0 40px;
      border: #ffffff 2px solid;
      box-shadow: 0px 0px 0px 2px rgb(255 141 10);
      &:hover {
        border: #ffffff 2px solid;
      }
    }
  }
  ${BreakPoints.large} {
    .p-section-inner {
      .p-section-contact {
      }
    }
  }
`

/**
 * LP用ダウンロードフォーム-入力画面
 * @constructor
 */
const LpDownload01 = () => {
  const {
    contact,
    isError,
    finish,
    loading,
    onChangeContact: onChange,
    execDownload: onClick,
  } = useDownload()

  useEffect(() => {
    if (finish) {
      window.gtag('event', 'click', {
        event_category: 'download',
        event_label: 'd-lpa',
      })
      navigate('/lpa/thanks/')
    }
  }, [finish])

  return (
    <Wrapper>
      <div id="lp-download" className="p-section-inner">
        <h1>
          <img
            src="/images/lp/lp01/lpContentTtl07.png"
            alt="資料ダウンロード"
          />
        </h1>
        <div className="p-section-contact">
          {/** 会社名 */}
          <div className="item-wrap">
            <p className="label-area">
              <span className="txt-bold">会社名</span>
              <span className="txt-color-alert txt-size-small require">
                *必須
              </span>
              {isError && !contact.company && (
                <span className="txt-color-alert txt-size-small err">
                  会社名を入力してください
                </span>
              )}
            </p>
            <input
              value={contact.company}
              placeholder="○○株式会社"
              onChange={onChange('company')}
            />
          </div>

          {/** 氏名 */}
          <div className="item-wrap">
            <p className="label-area">
              <span className="txt-bold">ご担当者名</span>
              <span className="txt-color-alert txt-size-small require">
                *必須
              </span>
              {isError && !contact.name && (
                <span className="txt-color-alert txt-size-small err">
                  氏名を入力してください
                </span>
              )}
            </p>
            <input
              value={contact.name}
              placeholder="田中太郎"
              onChange={onChange('name')}
            />
          </div>

          {/** メールアドレス */}
          <div className="item-wrap">
            <p className="label-area">
              <span className="txt-bold">メールアドレス</span>
              <span className="txt-color-alert txt-size-small require">
                *必須
              </span>
              {isError && !contact.email && (
                <span className="txt-color-alert txt-size-small err">
                  メールアドレスを入力してください
                </span>
              )}
              {isError &&
                contact.email &&
                !StringUtil.checkFormatMail(contact.email) && (
                  <span className="txt-color-alert txt-size-small err">
                    メールアドレスの形式が間違っています
                  </span>
                )}
            </p>
            <input
              value={contact.email}
              placeholder="info@sample.jp"
              onChange={onChange('email')}
            />
          </div>

          {/** 電話番号 */}
          <div className="item-wrap">
            <p className="label-area">
              <span className="txt-bold">電話番号</span>
              <span className="txt-color-alert txt-size-small require">
                *必須
              </span>
              {isError && !contact.tel && (
                <span className="txt-color-alert txt-size-small err">
                  電話番号を入力してください
                </span>
              )}
            </p>
            <input
              value={contact.tel}
              placeholder="0312345678"
              onChange={onChange('tel')}
            />
          </div>

          {/** 現在の勤怠管理方法 */}
          <div className="item-wrap">
            <p className="label-area">
              <span className="txt-bold">現在の勤怠管理方法</span>
              <span className="txt-color-alert txt-size-small require">
                *必須
              </span>
              {isError && !contact.managementType && (
                <span className="txt-color-alert txt-size-small err">
                  現在の勤怠管理方法を選択してください
                </span>
              )}
            </p>
            <div className="p-select">
              <select
                required
                value={contact.managementType}
                onChange={onChange('managementType')}
              >
                <option value="" hidden>
                  選択してください
                </option>
                {ManagementKindList.map((val) => (
                  <option key={val.kind} value={val.kind}>
                    {val.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/** プラポリ同意 */}
          <div className="txt-center">
            <CommonCheckbox
              checked={contact.agree}
              onChange={onChange('agree')}
            >
              <a href="/policy" target="_blank">
                プライバシーポリシー
              </a>
              に同意する
            </CommonCheckbox>
            {isError && !contact.agree && (
              <span className="txt-color-alert txt-size-small err">
                プライバシーポリシーに同意してください。
              </span>
            )}
          </div>

          {/** ダウンロード */}
          <OfficialCommonBtn
            size="large"
            level="strong"
            className="p-btn-download done-lp01A"
            onClick={() => onClick(SiteKind.LPA)}
            disabled={loading}
          >
            資料ダウンロード
          </OfficialCommonBtn>
        </div>
      </div>
    </Wrapper>
  )
}

export default LpDownload01
